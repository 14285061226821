import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate, Link } from 'gatsby'
import { isEmpty } from 'lodash'

import LayoutSignup from 'components/LayoutSignup'
import Steps from 'components/Steps'
import SEO from 'components/SEO'
import SubscriptionDetailsCard from 'components/Signup/SubscriptionDetailsCard'
import VideoIcon from 'images/landing-page/icons/video-icon.svg'

import LiveLogo from 'images/landing-page/icons/live-red-icon.svg'
import { GA_EVENTS } from 'components/LandingPage/constants'

const SignUpKeys = ({ location }) => {
  const { state } = location
  const { userRegistered, org: orgSlug, email, tier } = state || {}

  useEffect(() => {
    if (!userRegistered || isEmpty(orgSlug)) {
      navigate('/')
    } else {
      window.gtag('event', GA_EVENTS.SIGN_UP_COMPLETED, {
        user_email: email,
        user_org: orgSlug,
        subscription_type: tier,
      })
    }
  }, [location])

  return (
    <LayoutSignup>
      <SEO title="Welcome to Live!" />
      <header>
        <div className="logo">
          <Link to="/">
            <LiveLogo />
          </Link>
        </div>
      </header>
      <div className="signup-form">
        <section className="form-container">
          <Steps step={4} />
          <div className="form-section is-active success-screen">
            <VideoIcon />
            <h2 className="form-heading">Welcome to Live!</h2>
            <span>Your account was created and you’re ready to go Live.</span>
            <div className="form-buttons">
              <a
                href={`https://${process.env.FRONTEND_DOMAIN}/login?domain=${orgSlug}`}
                target="_blank"
                className="button --primary"
                rel="noreferrer"
              >
                Go to your Live dashboard
              </a>
            </div>
          </div>
          <div className="bottom-row">
            <div className="rect" />
            <SubscriptionDetailsCard />
          </div>
        </section>
      </div>
      {/* <footer className="signup-footer" /> */}
    </LayoutSignup>
  )
}

SignUpKeys.propTypes = {
  location: PropTypes.object,
}

export default SignUpKeys
